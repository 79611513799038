import type { VueConstructor } from 'vue'
import VIcon from 'vuetify/lib/components/VIcon'
import ClickOutside from 'vuetify/lib/directives/click-outside'
import Ripple from 'vuetify/lib/directives/ripple'
import Intersect from 'vuetify/lib/directives/intersect'
import Resize from 'vuetify/lib/directives/resize'
import Vuetify from 'vuetify/lib/framework'
import { isProduction } from '@whispli/utils/env'
import { COLORS_ENUM } from '@whispli/ui/constants'
import type VueI18n from 'vue-i18n/types'

export let vuetify: Vuetify | null = null

export default async (vue: VueConstructor, mdi?: Record<string, string> = {}): typeof Vuetify => {
  /**
   * @note When testing, DO NOT `localVue.use(Vuetify)`, `this.$vuetify` getter fails.
   * It's been the case since 2.0 when we started using Vue.extend().
   * Most of the time it's fine but anything that depends on this.$vuetify will not work.
   * v-icon didn't use $vuetify until v2.4 which is why it's only just become an issue for you.
   * @see https://github.com/vuetifyjs/vuetify/issues/13163#issuecomment-786748938
   * @see https://vuetifyjs.com/en/getting-started/unit-testing/#spec-tests
   */
  if (import.meta.env.MODE !== 'test') {
    vue.use(Vuetify, {
      components: { VIcon },
      directives: {
        ClickOutside,
        Intersect,
        Resize,
        Ripple,
      },
    })
  }

  const options: any = {
    silent: isProduction,
    theme: { dark: false, themes: {} },
    icons: {
      iconfont: 'mdiSvg',
      // @see vuetify/src/services/icons/presets/mdi.ts
      values: mdi,
    },
    rtl: false,
  }

  if (import.meta.env.MODE === 'test') {
    options.theme.themes.light = {
      ...Object.fromEntries(
        Object.values(COLORS_ENUM).map(e => [
          e,
          {
            lighten5: '#5063fe',
            lighten4: '#5063fe',
            lighten3: '#5063fe',
            lighten2: '#5063fe',
            lighten1: '#5063fe',
            base: '#5063fe',
            darken1: '#5063fe',
            darken2: '#5063fe',
            darken3: '#5063fe',
            darken4: '#5063fe',
          },
        ])
      ),
    }

    options.lang = {
      t: (str) => str,
      current: 'en',
    }
  } else {
    options.theme.themes.light = (await import('@whispli/styles/themes/light'))?.light ?? {}
  }

  vuetify = new Vuetify(options)

  return vuetify
}

export const VUE_KEY = 'vuetify' as const

/**
 * Use VueI18n engine for localization of Vuetify components.
 * @note Function MUST BE bound to the VueI18n instance -- `this` binding is lost when compiled to ES5.
 * @note DO NOT destructure args `VueI18n.t` will resolve replacements from either an indexed array
 * ie. `$vuetify.dataFooter.pageText` or a string keyed object.
 */
export const useI18n = (
  i18n: VueI18n,
  vuetify: typeof Vuetify
) => vuetify.framework.lang.t = (function t(this: VueI18n, key: string, ...args: any[]): string {
  // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
  return this.t.call(this, key, args)
}).bind(i18n)
